<template>
  <router-view />
</template>

<script>

export default {
  components: {
  },
  data () { 
    return {
      records: null,
      recaptchaLoaded: false,
      showTest: false,
    }
  },
  methods: {
    formatItemDate (item) {
      let out = item.Year
      if (item.Month) out = item.Month + '-' + out
      if (item.Day) out = item.Day + '-' + out

      return out
    },
  },
  mounted () {
    // Recaptcha
    const script = document.createElement('script')
          script.src = 'https://www.google.com/recaptcha/api.js?onload=recaptchaLoad&render=explicit'
          script.async = true
          script.defer = true

    window.recaptchaLoad = () => {
      this.recaptchaLoaded = true
    }

    document.head.appendChild(script)

    this.showTest = (location.search == '?test')
  }
}

</script>

<style lang="scss">
  @import 'assets/scss/main';
</style>
